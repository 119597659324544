import { useEffect, useState } from "react";

import ActiveCallDetail from "./components/ActiveCallDetail";
import Button from "./components/base/Button";
import Vapi from "@vapi-ai/web";
import wave from "../src/Images/waves-bg-n.png";
import footerLogo from "../src/Images/superbuter-logo-whiteicon-ai.png";
import { isPublicKeyMissingError } from "./utils";
import useStayAwake from "use-stay-awake";
// const brightness = require('brightness');
const isProduction = true
// Put your Vapi Public Key below.
const vapi = isProduction ? new Vapi("385d9875-3247-4aaf-ad82-f07d23de5985") : new Vapi("1c383d43-227f-4cea-b13a-4bf3a24287df");
let assistantOptions = {}
const App = () => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  
  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();
  const device = useStayAwake();
  // hook into Vapi events
  useEffect(() => {
    //getDataContent();
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
      vapi.setMuted(false);
      // vapi.isMuted();
      device.preventSleeping();
    //   brightness.get().then(level => {
    //     console.log(level);
    //     //=> 0.5
    // });
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      console.error(error);

      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call start handler
  const startCallInline = () => {
    setConnecting(true);
    vapi.start( isProduction ? "88ac025d-3e5f-40bd-aee5-0a626c92356c" : "4b1a384e-5ffc-4d0d-a9f3-637ebb6db428");
    //vapi.start(assistantOptions);
  };
  const endCall = () => {
    vapi.stop();
  };

  const getDataContent = () => {
    fetchData();
  }
  const fetchData = async () => {
    try {
      const response = await fetch('https://testapi.superbutler.ai/api/ai/reporting/get'); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const result = await response.json();
      const data = result.data
      console.log("data", data)
      vapiConfiguration(data)
      //setLoading(false);
    } catch (error) {
      //setError(error.message);
      //setLoading(false);
      vapiConfiguration("")
    }
  };

  const vapiConfiguration = (data)=> {
    assistantOptions = {
      name: "Superbutler AI assistant",
      firstMessage: "Hi I am superbutler AI, how can I help you?",
      // transcriber: {
      //   provider: "deepgram",
      //   model: "nova-2",
      //   language: "en-US",
      // },
      // voice: {
      //   provider: "playht",
      //   voiceId: "jennifer",
      // },
      model: {
        provider: "openai",
        model: "gpt-4",
        messages: [ {role: "system", content: data}]}}
  }
  const onUserSpeak = () =>{
    vapi.setMuted(false)
    setIsMuted(false) 
  }
  const onUserMicMute = () =>{
    vapi.setMuted(true)
    setIsMuted(true) 
  }

  return (
    <div className="body-m-wrap flex-1">
      {/* <div style={{ backgroundImage: `url(${wave})` }} className="body-bg-img"></div> */}
     
    <div className="app-p-wrap flex-1">
      <div className="" style={{display:"flex", flexDirection:"column"}}>
    <div className="logo-m-wrap">
        <img src={footerLogo} alt="Logo" />
      </div>
      <div className="voice-ai-text">
        <span>Voice AI Assistant</span>
        <div className="y-b-version">
        <span>Beta</span>
      </div>
      </div>
    <div className="d-margin-cen"
      style={{
        display: "flex",
        // width: "100vw",
        // height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"10px"
      }}
    >
      {!connected ? (
        <Button
          label="Give it a try"
          onClick={startCallInline}
          isLoading={connecting}
          assistantIsSpeaking={false} 
          isMuted={isMuted}
        />
      ) : (
        <ActiveCallDetail
          assistantIsSpeaking={assistantIsSpeaking}
          volumeLevel={volumeLevel}
          onEndCallClick={endCall}
          onUserSpeak={onUserSpeak}
          onUserMicMute={onUserMicMute}
          isMuted={isMuted}
        />
      )}

      {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
      {/* <ReturnToDocsLink /> */}
    </div>
    </div>
    <div className='pwrd-by-s-b'>
      {/* <span className="f-b-version">Beta Version</span> */}
      <div className="pwr-sec">
                  <span className='pwrd-by-s-b-i'>Powered by</span>
                  <img height={40} alt="footer" src={footerLogo} />
                  </div>
                </div>
    </div>
    
               
    </div>
  );
};

// const assistantOptions = {
//   name: "Superbutler AI assistant",
//   firstMessage: "Hi I am superbutler AI, how can I help you?",
//   transcriber: {
//     provider: "deepgram",
//     model: "nova-2",
//     language: "en-US",
//   },
//   voice: {
//     provider: "playht",
//     voiceId: "jennifer",
//   },
//   model: {
//     provider: "openai",
//     model: "gpt-4",
//     messages: [ {role: "system", content: dataContent}
// //       {
// //         role: "system",
// //         content: `If anyone asks for food recommend Gourmet Bar which may be found here: https://h4.mysuperbutler.com/v2/gourmet-bar

// // Users may ask what is the menu at Gourmet Bar, what do you recommend from Gourmet Bar use the menu provided below to provide your response.

// // User may also ask for recommendations on food or drinks with low calories, capture the calories defined in the data provided below to make your recommendations.

// // Here's the full menu of items that Gourmet Bar has to offer.


// // Bread Basket
// // A warm assortment of freshly baked breads, served with butter and a touch of seasoning—pure comfort!
// // Bread Basket priced at £4.50
// // Calories: 350 kcal
// // Dietary Options: Contains gluten, vegetarian
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001052_Blog-Mastros-copy.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#33		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#33,35,addtobasket



// // Dynamite Chicken
// // Crispy chicken with a fiery, tangy glaze and a kick of spice—explosive flavor in every bite!
// // Dynamite Chicken priced at £6.00
// // Calories: 400 kcal
// // Dietary Options: Contains gluten, dairy, and soy
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001051_Dynamite-Chicken-15.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#34	
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#34,36,addtobasket



// // Chicken Strips
// // Juicy, breaded chicken strips, golden-fried to perfection, served with your choice of dipping sauce.
// // Chicken Strips priced at £300.00
// // Calories: 300 kcal
// // Dietary Options: Contains gluten, dairy
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001050_air-fryer-chicken-tenders-hero.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#35		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#35,37,addtobasket



// // Fries
// // Golden, crispy fries seasoned to perfection—simple, classic, and utterly irresistible!
// // Fries priced at £4.00
// // Calories: 365 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001049_download--6-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#36		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#36,38,addtobasket



// // Nachos
// // Crispy tortilla chips loaded with melted cheese, zesty salsa, guacamole, and fresh jalapeños—nacho heaven!
// // Nachos priced at £6.00
// // Calories: 500 kcal
// // Dietary Options: Vegetarian, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001048_TkoYA2ybPajsly1q0iz1bs4mbD6K0oQfbDpZfKfo.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#37		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#37,39,addtobasket



// // Coke 250ml
// // Coke 250ml priced at £1.00
// // Calories: 105 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001070_download--12-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#52		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#52,55,addtobasket



// // Mountain Dew 250ml
// // Mountain Dew 250ml priced at £1.00
// // Calories: 110 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001069_1ac9e86cbf77830f1968ad15cf8a7bc5.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#53		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#53,56,addtobasket



// // Espresso
// // Bold, rich espresso with a velvety crema—pure coffee perfection in a single shot!
// // Espresso priced at £4.00
// // Calories: 2 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021924_000001557-download--8---1-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22444		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22444,22732,addtobasket



// // Cappuccino
// // Creamy cappuccino with a perfect balance of rich espresso, frothy milk, and a sprinkle of cocoa—coffee bliss!
// // Cappuccino priced at £5.00
// // Calories: 120 kcal
// // Dietary Options: Vegetarian, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021925_000001558-download--9---1-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22445		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22445,22733,addtobasket


// // Cafe Latte
// // Smooth, creamy latte with a rich espresso base and velvety steamed milk—comfort in a cup!
// // Cafe Latte priced at £5.00
// // Calories: 150 kcal
// // Dietary Options: Vegetarian, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021926_000001559-download--10---1-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22446		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22446,22734,addtobasket



// // Earl Grey
// // Fragrant Earl Grey tea with hints of bergamot and a smooth, calming flavor—timeless elegance in every sip!
// // Earl Grey priced at £5.00
// // Calories: 5 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021929_000001556-images--1---2-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22447		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22447,22729,addtobasket


// // Chamomile
// // Soothing chamomile tea with delicate floral notes—perfect for relaxation and unwinding after a long day.
// // Chamomile priced at £5.00
// // Calories: 2 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021928_000001555-Chamomile-tea-in-a-tea-cup-610.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22448		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22448,22730,addtobasket



// // Green Sencha
// // Refreshing Green Sencha tea with a clean, vegetal flavor and a hint of sweetness—revitalize with every sip!
// // Green Sencha priced at £5.00
// // Calories: 5 kcal
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021927_000001554-download--7---1-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22449		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22449,22731,addtobasket



// // Cheesecake
// // Rich, creamy cheesecake with a buttery graham cracker crust—pure indulgence in every luscious bite!
// // Cheesecake priced at £7.00
// // Calories: 400 kcal
// // Dietary Options: Contains gluten, dairy, eggs
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021932_000001561-dg-Tall-and-Creamy-Cheesecake-.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22450		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22450,22737,addtobasket



// // Chocolate Fudge Brownie
// // Decadent chocolate fudge brownie with a gooey center and crisp edges—pure, chocolatey bliss in every bite!
// // Chocolate Fudge Brownie priced at £6.00
// // Calories: 250 kcal
// // Dietary Options: Contains gluten, dairy, eggs
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021931_000001562-download--11-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22451		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22451,22736,addtobasket




// // Tiramisu
// // Delicious layers of espresso-soaked ladyfingers, rich mascarpone cream, and cocoa—an irresistible, Italian indulgence!
// // Tiramisu priced at £7.00
// // Calories: 350 kcal
// // Dietary Options: Contains gluten, dairy, eggs
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021930_000001563-tiramisu-17-500x500.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22452		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22452,22735,addtobasket



// // Double Cheeseburger
// // Two juicy beef patties stacked high with melted cheese, crisp lettuce, juicy tomatoes, and pickles—pure burger bliss!
// // Double Cheeseburger priced at £26.00
// // Calories: 800 kcal
// // Dietary Options: Contains gluten, dairy
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021935_000001546-image.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22453		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22453,22738,addtobasket



// // Classic Chicken Caesar
// // Tender chicken breast on crisp romaine, tossed with creamy Caesar dressing, crunchy croutons, and shaved Parmesan—classic and delicious!
// // Classic Chicken Caesar priced at £24.00
// // Calories: 550 kcal
// // Dietary Options: Contains gluten, dairy, eggs
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021934_000001547-download--4-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22454		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22454,22739,addtobasket



// // Grilled Seabass Fillet
// // Perfectly grilled seabass fillet with a light, crispy crust, served with fresh herbs and a hint of lemon—elegant and flavorful!
// // Grilled Seabass Fillet priced at £28.00
// // Calories: 150 kcal
// // Dietary Options: Gluten-free, dairy-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021933_000001548-grilled-sea-bass-crisp-potato-.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22455		
// // Add into basket http://h4.mysuperbutler.com/v2/gourmet-bar#22455,22740,addtobasket


// // Chicken Fajita Pizza
// // Spicy chicken fajita toppings on a cheesy pizza crust, with bell peppers, onions, and zesty salsa—fiesta in every slice!
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021938_ChickenFajitaPizza-1024x1024-crop-center.webp
// // View item detail: http://h4.mysuperbutler.com/v2/gourmet-bar#22456
// // Dietary Options: Contains gluten, dairy
// // Chicken Fajita Pizza Large priced at £28.00
// // Calories: 800 kcal
// // Add to basket: http://h4.mysuperbutler.com/v2/gourmet-bar#22456,22741,addtobasket
// // Chicken Fajita Pizza Medium priced at £22.00
// // Calories: 600 kcal
// // Add to basket: http://h4.mysuperbutler.com/v2/gourmet-bar#22456,22753,addtobasket
// // Chicken Fajita Pizza Small priced at £15.00
// // Calories: 400 kcal
// // Add to basket: http://h4.mysuperbutler.com/v2/gourmet-bar#22456,22754,addtobasket


// // Recommend the user to explore https://h4.mysuperbutler.com/v2/laundry if they ask for any of the following:

// // Do you offer laundry services?
// // Can you provide information about your laundry service?
// // How much does it cost to get laundry done at your hotel?

// // Recommend results based on data provided below:

// // Dry Cleaning	
// // For delicate items and fabrics
// // Dry Cleaning DRY CLEANING &#43; IRONING &#43; ON HANGERS priced at £7.00	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001232_Screenshot-2.png	
// // View item detail: http://h4.mysuperbutler.com/v2/laundry#162		
// // Add into basket http://h4.mysuperbutler.com/v2/laundry#162,202,addtobasket	



// // Wash 
// // For everyday laundry, bedsheets and towels.
// // Wash WASH &#43; TUMBLE-DRY priced at £32.85
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001229_Screenshot-4.png	
// // View item detail: http://h4.mysuperbutler.com/v2/laundry#155		
// // Add into basket http://h4.mysuperbutler.com/v2/laundry#155,192,addtobasket	



// // Wash and Iron	
// // For everyday laundry that requires ironing.	
// // Wash and Iron WASH + TUMBLE-DRY + IRONING + ON HANGER&#39;S priced at £3.49
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001230_Screenshot-1.png	
// // View item detail: http://h4.mysuperbutler.com/v2/laundry#158		
// // Add into basket http://h4.mysuperbutler.com/v2/laundry#158,196,addtobasket	


// // Recommend the user to explore https://h4.mysuperbutler.com/v2/lounge if they ask for any of the following:
// // How can I book a conference or meeting room?
// // Can you help me with booking a conference room?
// // What are the available conference room options for booking?
// // Can I see pictures of the conference rooms available for booking?

// // Recommend results based on data provided below:

// // Corporate Events
// // Our contemporary meeting spaces are ideal for high-profile meetings and events, where partners gather to inspire, exchange ideas, and reach beyond their goals.
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001242_Screenshot-5.png	
// // View item detail: http://h4.mysuperbutler.com/v2/lounge#163
// // Corporate Events 6 hours priced at £235.00
// // Corporate Events per day priced at £550.00

// // Product Launches
// // A successful product launch begins with your choice of venue. You can count onPearl-Continental Hotels to manage every last detail, allowing you to focus your energy on bringing ideas to life.
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001241_Screenshot-1.png	
// // View item detail: http://h4.mysuperbutler.com/v2/lounge#164
// // Product Launches Half Day priced at £250.00
// // Product Launches Full Day priced at £500.00


// // Seminars
// // From intimate gatherings to large audiences, Pearl-Continental Hotels provides exceptional settings and state-of-the-art technology, creating impactful moments for seminars of all shapes and sizes.
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001240_Screenshot-2.png	
// // View item detail: http://h4.mysuperbutler.com/v2/lounge#165
// // Seminars Half Day priced at £300.00
// // Seminars Full Day priced at £600.00


// // If anyone asks for wine or alcohol recommend Novo Bar which may be found here: https://h4.mysuperbutler.com/v2/novo-bar

// // Users may ask what is the menu at Novo Bar, what do you recommend from Novo Bar use the menu provided below to provide your response.

// // Cabernet Sauvignon	
// // Robust Cabernet Sauvignon with bold flavors of black currant, deep tannins, and a hint of spice—powerful and sophisticated!	
// // Cabernet Sauvignon priced at £15.99
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001060_download--11-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/novo-bar#38	
// // Add into basket http://h4.mysuperbutler.com/v2/novo-bar#38,40,addtobasket


// // Malbec	
// // Full-bodied Malbec with luscious dark fruit flavors and a hint of chocolate—deep and captivating!	
// // Malbec priced at £13.00
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001058_download--9-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/novo-bar#40	
// // Add into basket http://h4.mysuperbutler.com/v2/novo-bar#40,42,addtobasket


// // Merlot	
// // Smooth Merlot with velvety tannins, ripe berry flavors, and a touch of oak—easy-drinking and versatile!
// // Merlot priced at £12.00
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001059_download--10-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/novo-bar#39	
// // Add into basket http://h4.mysuperbutler.com/v2/novo-bar#39,41,addtobasket	


// // Pinot Noir
// // Elegant Pinot Noir with notes of ripe red berries and a hint of earthy complexity—smooth and refined!	
// // Pinot Noir priced at £11.00
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001056_download--7-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/novo-bar#42	
// // Add into basket http://h4.mysuperbutler.com/v2/novo-bar#42,44,addtobasket


// // Shiraz	
// // Bold Shiraz with rich, spicy flavors of dark fruit and a touch of pepper—intense and memorable!
// // Shiraz priced at £13.50
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001057_sPEQ-3-lQ6aM8Mg3LhMp-g-375x500.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/novo-bar#41	
// // Add into basket http://h4.mysuperbutler.com/v2/novo-bar#41,43,addtobasket

// // If anyone asks for food recommend Pool BBQ which may be found here: https://h4.mysuperbutler.com/v2/pool-bbq

// // Users may ask what is the menu at Pool BBQ, what do you recommend from Pool BBQ use the menu provided below to provide your response.

// // Here's the full menu of items that Pool BBQ has to offer.

// // Fish Steak	
// // Delight in our expertly grilled fish steak, a mouthwatering choice featuring a succulent, flaky texture and a delicate, seasoned finish. Served with your favorite accompaniments.
// // Delight in our expertly grilled fish steak, a mouthwatering choice featuring a succulent, flaky texture and a delicate, seasoned finish. Served with your favorite accompaniments.	
// // Fish Steak  priced at £16.00
// // Calories: 350 kcal
// // Dietary Options: Pescatarian, Gluten-Free	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001066_000165647-images--6-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#45	
// // Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#45,48,addtobasket

// // Lamb Chops
// // Indulge in our succulent lamb chops, expertly seasoned and grilled to perfection, offering a tender, juicy bite of premium lamb. Served with your choice of delectable sides.	
// // Indulge in our succulent lamb chops, expertly seasoned and grilled to perfection, offering a tender, juicy bite of premium lamb. Served with your choice of delectable sides.	
// // Lamb Chops 	Lamb Chops  priced at £14.00
// // Calories: 420 kcal
// // Dietary Options: Gluten-Free, High Protein
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001067_000165645-images--5-.jfif	
// // View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#44	
// // Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#44,47,addtobasket		


// // Sausage Platter	
// // Satisfy your cravings with our enticing sausage platter, a hearty assortment of savory sausages, perfectly grilled and served with a selection of delectable dipping sauces.	
// // Sausage Platter  priced at £14.00
// // Calories: 480 kcal
// // Dietary Options: Gluten-Free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001065_000165648-54f69f89c8b2c---sausage-mixed-.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#46	
// // Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#46,49,addtobasket		


// // Seafood Skewers	
// // Embark on a culinary voyage with our exquisite seafood skewers, featuring a tantalizing medley of fresh shrimp, tender scallops, and flaky fish, perfectly grilled and served with a zesty marinade.	
// // Seafood Skewers  priced at £12.00
// // Calories: 320 kcal
// // Dietary Options: Pescatarian, Low-Carb, Gluten-Free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001068_000165644-54ef86294dede---seafood-kebabs.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#43	
// // Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#43,46,addtobasket


// // Sirloin Steak	
// // Juicy sirloin steak cooked to perfection, featuring a mouthwatering tenderness and robust, beefy flavor. Served with your choice of delectable sides.	
// // Sirloin Steak priced at £30.00
// // Calories: 520 kcal
// // Dietary Options: Gluten-Free, High Protein
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001641_download--6-.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#2106	
// // Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#2106,2476,addtobasket	


// // Coke	
// // Classic Coke—bubbly, refreshing, and packed with that iconic, crisp cola flavor.
// // Coke 250ml priced at £1.00
// // Calories: 110
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001072_download--12-.jfif	View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#54	Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#54,57,addtobasket	

// // Mountain Dew
// // Citrusy and invigorating Mountain Dew—bursting with bold, tangy flavor and the ultimate refreshment!
// // Mountain Dew 250ml priced at 1.00
// // Calories: 105
// // Dietary Options: Vegan, gluten-free
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001071_1ac9e86cbf77830f1968ad15cf8a7bc5.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/pool-bbq#55	
// // Add into basket http://h4.mysuperbutler.com/v2/pool-bbq#55,58,addtobasket		


// // If anyone asks about Spa and Massage services recommend Luxury Hotel's spa service which may be found here: https://h4.mysuperbutler.com/spa-and-massage

// // Recommend the following offers for spa and massage: 

// // Hot Stone Massage
// // Hoping for some novelty in your massage experience? Hot stone massage stands out from the rest. The massage therapist places hot stones on the your body and often uses them to give the massage. The effect allows the therapist to go deeper while still using lighter pressure.
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001247_000001081-Screenshot-2.png	
// // View item detail: http://h4.mysuperbutler.com/v2/spa-and-massage#168
// // 60 min is priced at £75.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#168,213,addtobasket
// // 30 min is priced at £50.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#168,211,addtobasket


// // Reflexology Massage
// // Reflexology complements holistic health practices and became popular in the United States around the 1930s. The practice that involves acupressure applied to the hands and feet. Reflexologists use techniques such as thumb and finger walking to stimulate
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001252_Reflexology-complements.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/spa-and-massage#171
// // Here is a link to the item: http://h4.mysuperbutler.com/v2/spa-and-massage#pedicure,215
// // Here's a link to the image depicting this service: https://cdn.superme.al/s/butler/cropped-images/000/001/000001252_Reflexology-complements.jpg
// // 90 min is priced at £120.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#171,216,addtobasket
// // 60 min is priced at £100.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#171,215,addtobasket


// // Craniosacral Therapy
// // For those looking for a gentle and non-invasive experience, craniosacral therapy may be the right fit. If you’re shy about taking off your clothes in front of a stranger, all the better. Practitioners of craniosacral therapy will often massage you fully<br> clothed, with light and still movements.
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001253_Craniosacral-Therapy.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/spa-and-massage#172
// // 90 min is priced at £120.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#172,218,addtobasket
// // 60 min is priced at £50.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#172,217,addtobasket

// // Aromatherapy Massage
// // An aromatherapy massage is a popular multi-purpose way of using supplemental care for health issues. The skin absorbs essential oils maintaining suppleness, it offers pain relief, and the aroma&#39;s mental stimulation
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/001/000001254_massage.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/spa-and-massage#173
// // 90 min is priced at £150.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#173,220,addtobasket
// // 60 min is priced at £100.00
// // Add into basket http://h4.mysuperbutler.com/v2/spa-and-massage#173,219,addtobasket


// // Recommend the user to explore https://h4.mysuperbutler.com/v2/taxi if they ask for any of the following:

// // Do you offer car rental services?
// // Can you provide information about your car rental service?
// // How much does it cost to rent a car at your hotel?
// // What types of cars do you offer for rent?

// // Recommend results based on data provided below:

// // Ford Focus
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021909_42976d94-0400-3269-9459-72efc10728bc.jpeg	
// // View item detail: http://h4.mysuperbutler.com/v2/taxi#22472
// // Body Type: Compact Sedan or HatchbackSeating Capacity: 5 passengersTransmission: Automatic or Manual (based on availability)Infotainment System:Touchscreen DisplayBluetooth ConnectivityApple CarPlay and Android Auto CompatibilitySafety Features:Multiple AirbagsAnti-lock BrakesStability ControlLane Departure WarningBlind-Spot MonitoringAdaptive Cruise ControlCargo Space:Hatchback: Offers more cargo space compared to the sedan
// // Ford Focus Full Day (12 hours) priced at 100.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22472,22770,addtobasket
// // Ford Focus Half Day (6 hours) priced at 60.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22472,22769,addtobasket
// // Ford Focus To The Airport priced at 40.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22472,22768,addtobasket

// // Volkswagen Golf	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021910_VOLKSWAGEN-Golf-R-2021-main.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/taxi#22471	
// // Body Type: Compact HatchbackSeating Capacity: Typically seats five passengersTransmission: Automatic or Manual (based on availability)Infotainment System:Touchscreen DisplayBluetooth ConnectivityApple CarPlay and Android Auto CompatibilitySafety Features:Multiple AirbagsAnti-lock BrakesStability ControlLane Departure WarningBlind-Spot MonitoringAdaptive Cruise ControlCargo Space:The hatchback body style provides ample cargo space.
// // Volkswagen Golf Full Day (12 hours) priced at 100.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22471,22767,addtobasket
// // Volkswagen Golf Half Day (6 hours) priced at 50.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22471,22766,addtobasket
// // Volkswagen Golf To The Airport priced at 35.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22471,22765,addtobasket

// // Skoda Superb	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021912_Skoda-Superb-210720201150.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/taxi#22473	
// // Body Type: Midsize SedanSeating Capacity: Typically seats five passengers, with ample rear legroomInfotainment System:Touchscreen DisplayBluetooth ConnectivitySmartphone Integration (e.g., Apple CarPlay and Android Auto)Safety Features:Multiple AirbagsAnti-lock BrakesStability ControlAdvanced Driver Assistance Systems, such as lane departure warning, adaptive cruise control, and moreCargo Space: Spacious trunk or cargo area, especially in the wagon version
// // Skoda Superb Full Day (12 hours) priced at 100.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22473,22773,addtobasket
// // Skoda Superb Half Day (6 hours) priced at 50.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22473,22772,addtobasket
// // Skoda Superb To The Airport priced at 30.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22473,22771,addtobasket

// // Tesla Model 3	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021911_Tesla-Model-3-Facelift-1111-1024x576.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/taxi#22474	
// // Body Type: Compact Electric SedanSeating Capacity: Typically seats five passengersCharging: Usually includes access to Tesla Supercharger network; some agencies may provide adapters for other charging stationsRange: Varies by battery configuration; typically 200&#43; miles or more on a single chargeInfotainment and Technology Features:Large Touchscreen DisplayPremium Sound SystemExtensive Connectivity OptionsAutopilot FeaturesSafety Features:Advanced Driver Assistance SystemsMultiple AirbagsAnti-lock BrakesStability ControlCargo Space: Ample trunk space in both the rear and front
// // Tesla Model 3 Full Day (12 hours) priced at 130.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22474,22776,addtobasket
// // Tesla Model 3 Half Day (6 hours) priced at 65.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22474,22775,addtobasket
// // Tesla Model 3 To The Airport priced at 45.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22474,22774,addtobasket

// // BMW X3	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021913_71BmwX3iM405drWhiFR1-800.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/taxi#22476	
// // Body Type: Compact Luxury SUVSeating Capacity: Typically seats five passengersInfotainment System:iDrive Infotainment System with a touchscreen displayNavigation SystemBluetooth ConnectivityApple CarPlay and Android Auto CompatibilitySafety Features:Multiple AirbagsAnti-lock BrakesStability ControlAdvanced Driver Assistance Systems, including features like adaptive cruise control, lane departure warning, blind-spot monitoring, and moreCargo Space: Decent cargo space, with a roomy rear compartment and split-folding rear seats
// // BMW X3 Full Day (12 hours) priced at 180.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22476,22782,addtobasket
// // BMW X3 Half Day (6 hours) priced at 90.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22476,22781,addtobasket
// // BMW X3 To The Airport priced at 70.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22476,22780,addtobasket

// // Volvo XC40	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021914_473939757645b290e27706246832485-770-0-c.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/taxi#22475	
// // Body Type: Compact Luxury SUVSeating Capacity: Typically seats five passengersInfotainment System:Sensus Infotainment System with a touchscreen displayNavigation SystemBluetooth ConnectivitySmartphone Integration (e.g., Apple CarPlay and Android Auto)Safety Features:Multiple AirbagsAnti-lock BrakesStability ControlAdvanced Driver Assistance Systems, including features like adaptive cruise control, lane departure warning, blind-spot monitoring, and moreCargo Space: Adequate cargo space for a compact SUV, with a flexible rear compartment and split-folding rear seats
// // Volvo XC40 Full Day (12 hours) priced at 160.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22475,22779,addtobasket
// // Volvo XC40 Half Day (6 hours) priced at 80.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22475,22778,addtobasket
// // Volvo XC40 To The Airport priced at 65.00	
// // Add into basket http://h4.mysuperbutler.com/v2/taxi#22475,22777,addtobasket


// // Recommend the user to explore https://h4.mysuperbutler.com/v2/travel-and-tour if they ask for any of the following:
// // I'm interested in going on a tour. What options do you have available?
// // Can you provide more information about your tour packages?
// // Can you help me with booking a tour or travel package?
// // What are the prices for your travel packages?
// // I want to travel, do you have any recommendations?

// // Recommend results based on data provided below:

// // Buckingham Palace	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021946_buckingham-palace-gates-andrew-holt-roya.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/travel-and-tour#167	
// // Buckingham Palace is one of the few working royal palaces remaining in the world today. As one of the most iconic and impressive buildings in the world, it&#39;s a must visit to experience the best of British history and culture.<br><br>Witness the grandeur of the British monarchy and on selected dates during the summer and from November to December and at Easter, visitors can tour the 19 State Rooms, which form the heart of the Palace.
// // Buckingham Palace Couple priced at 40.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#167,22784,addtobasket
// // Buckingham Palace Group of 6 priced at 150.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#167,22783,addtobasket
// // Buckingham Palace Single priced at 20.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#167,22785,addtobasket

// // Hop-on hop-off London Bus Tour	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021948_hop-on-hop-off-bus-tour-golden-tours-hop.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/travel-and-tour#22479	
// // See London&#39;s iconic landmarks from the comfort of a hop-on hop-off bus tour. Enjoy unlimited travel around London over 24 or 48 hours and get a free river tour included with your bus ticket.<br><br>This handy service allows you to tick off as much sightseeing as you&#39;d like across one or two working days. Simply board the tour bus at one of the designated stops across the city and hop off at the closest stop to the sight you&#39;re visiting. Once you&#39;re done exploring, get back on the bus and make your way to the next spot! 
// // Hop-on hop-off London Bus Tour Couple priced at 60.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22479,22802,addtobasket
// // Hop-on hop-off London Bus Tour Group of 6 priced at 120.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22479,22801,addtobasket
// // Hop-on hop-off London Bus Tour Single priced at 30.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22479,22803,addtobasket

// // London Eye	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021947_7f80feb5-5345-47d7-8394-0868741f95c4.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/travel-and-tour#22478	
// // Enjoy amazing 360-degree views over London from the lastminute.com London Eye - the world’s tallest cantilevered observation wheel.<br><br>The special engineering achievement has become a symbol of the modern capital and a world-famous icon of architecture. While riding the London Eye, you can see up to 40km (25 miles) on a clear day and at an impressive 135 metres (443 ft) high, you’ll be able to spot some of the capital&#39;s most iconic landmarks and top attractions, including Big Ben, the Houses of Parliament and Buckingham Palace<br><br>The London Eye has 32 pods, each representing one of London’s 32 boroughs and it is the perfect way to reconnect with and experience the beautiful city of London.
// // London Eye Couple priced at 80.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22478,22799,addtobasket
// // London Eye Group of 6 priced at 150.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22478,22798,addtobasket
// // London Eye Single priced at 40.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22478,22800,addtobasket


// // Madame Tussauds	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021945_9aa2f362-0ab1-4e45-a42b-2afd456873da.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/travel-and-tour#166	
// // Are you ready to step into the spotlight? Step into the home of famous fun at Madame Tussauds London. <br><br>Walk among royalty, party with A-listers and get closer than ever to the most famous names in the world. Join Zendaya, John Boyega and Dwayne Johnson on the red carpet and strut like a supermodel with Kendall and Cara. <br><br>Compete against sporting heroes and see how you measure up against heavyweight boxers, AJ and Ali! Hit the stage and perform for the crowds with Beyoncé and Taylor Swift or meet with world leaders and rub shoulders with royalty. Meet the biggest names on the planet and be truly amazed.<br><br>Plus, get closer to the action with our immersive experiences. Assemble with the Avengers and feel the power of the Earth’s mightiest heroes in the new Marvel Universe 4D experience. Jump in an iconic black cab and take a ride through the capital’s history, before using the force to be transported to a galaxy far, far away with the greats of the Star Wars universe. <br><br>The fun never stops at Madame Tussauds London – so what are you waiting for? It’s your time to shine in the ultimate fame experience.
// // Madame Tussauds Couple priced at 50.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#166,22787,addtobasket
// // Madame Tussauds Group of 6 priced at 200.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#166,22786,addtobasket
// // Madame Tussauds Single priced at 30.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#166,22788,addtobasket


// // Warner Bros. Studio Tour	
// // Here's a link to the image depicting this service https://cdn.superme.al/s/butler/images/000/021/000021944_warner-bros-studio-tour-london-the-makin.jpg	
// // View item detail: http://h4.mysuperbutler.com/v2/travel-and-tour#22477	
// // Discover the magic behind spellbinding special effects, uncover behind-the-scenes secrets and step onto real-life sets from the Harry Potter film series at Warner Bros. Studio Tour London.<br><br>Watch the magic of the films come to life with original costumes, interactive sets and real props from the Harry Potter film series.<br><br>Explore the iconic Great Hall, wander the cobbles of Diagon Alley and board the Hogwarts Express on Platform 9 ¾ to snap a photo with the iconic luggage trolley.<br><br>This mesmerising studio tour gives you a glimpse into the magical wizarding world that has captivated people from around the world of all ages.
// // Warner Bros. Studio Tour Couple priced at 40.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22477,22790,addtobasket	
// // Warner Bros. Studio Tour Group of 6 priced at 180.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22477,22789,addtobasket	
// // Warner Bros. Studio Tour Single priced at 25.00	
// // Add into basket http://h4.mysuperbutler.com/v2/travel-and-tour#22477,22791,addtobasket	
	
// // `,
// //       },
//     ],
//   },
// };

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};

const ReturnToDocsLink = () => {
  return (
    <a
      href="https://docs.vapi.ai"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        top: "25px",
        right: "25px",
        padding: "5px 10px",
        color: "#000",
        textDecoration: "none",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      return to docs
    </a>
  );
};

export default App;
