import React from 'react';
import AssistantSpeechIndicator from "./call/AssistantSpeechIndicator";
import Button from "./base/Button";
// import VolumeLevel from "./call/VolumeLevel";
import SoundWave from "./SoundWave";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from 'swiper/modules';


const ActiveCallDetail = ({ assistantIsSpeaking, volumeLevel, onEndCallClick, isMuted, onUserSpeak, onUserMicMute }) => {
  return (
    <div>
      {/* <div className='y-b-version'>
        <span>Beta Version</span>
      </div> */}
      <div className='opacity-animation-waves'
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          border: "1px solid #ddd",
          borderRadius: "20px",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
          background:"rgba(0,0,0,0.7)",
        }}
      >
       
        <AssistantSpeechIndicator isSpeaking={assistantIsSpeaking} />
        <SoundWave isSpeaking={assistantIsSpeaking} />
        {/* <VolumeLevel volume={volumeLevel} /> */}
      </div>
      <div className='ask-wrapper'>
        <span className='can-ask-text'>You can ask;</span>
        <Swiper
      direction="vertical"
      loop={true}
      slidesPerView={1}
      spaceBetween={0}
      autoplay={{
        delay: 3000, // 4 seconds
        disableOnInteraction: false, // Keep autoplay after interaction
      }}
      allowTouchMove={false}
      speed={1000}
      modules={[Autoplay]}
      //autoHeight={true}
    >
        <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>Any good dinner spots nearby?</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>Can I get fresh towels?</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>Do you offer same-day laundry?</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>What does the spa offer?</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>What cars can I rent?</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>Can I book a meeting room?</span>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="ask-wrapper-txt">
          <span className='ask-wrapper-sp'>Do you sell souvenirs?</span>
        </div>
      </SwiperSlide>
        </Swiper>
      </div>
      
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Button label="End" onClick={onEndCallClick} onUserSpeak={onUserSpeak} onUserMicMute={onUserMicMute} assistantIsSpeaking={true} isMuted={isMuted} />
      </div>
     
    </div>
  );
};

export default ActiveCallDetail;
